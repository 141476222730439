import { FunctionComponent } from "react";
import {
    Link
  } from "react-router-dom";
const Footer: FunctionComponent = ({

}) => {
    return (
        <>
            <footer className="relative bottom-0 w-8/12 max-md:w-full text-base mx-auto max-sm:text-[16px] text-gray-500 pt-24">
                <div className="flex flex-wrap max-sm:flex-col justify-between gap-5 max-sm:ml-[10%] max-sm:gap-10">
                    <div className="gap-16 flex flex-wrap flex-col max-md:flex-nowrap max-md:flex-row  max-md:float-none max-sm:flex-col max-sm:pb-12 float-left">
                        <img
                            className="w-[248px] max-sm:w-4/12 max-sm:h-[40px] h-[85px] object-cover"
                            alt="Dansk psykolog forening"
                            title="Dansk psykolog forening"
                            src="../image-41@2x.png"
                            loading="lazy"
                        />
                        <img
                            className="w-[150.58px] max-sm:w-4/12 max-sm:h-auto h-[90px] object-cover"
                            alt="Zenna logo 2"
                            title="Zenna logo 2"
                            src="../image-42@2x.png"
                            loading="lazy"
                        />
                    </div>
                    <div className="gap-3 flex-gap flex flex-wrap flex-col">
                        <b className="text-gray-700">Ydelser</b>
                        <div className="">
                           <Link className="text-indigo" to={"/ydelser/hsp"}>Særligt sensitive</Link> 
                        </div>
                        <div className="">
                        <Link className="text-indigo" to={"/ydelser/stressterapi"}>Stress</Link>  og  <Link className="text-indigo" to={"/ydelser/kriseterapi"}>kriseterapi</Link>
                        </div>
                        <div className="">
                        <Link className="text-indigo" to={"/ydelser/healing"}>Healing</Link> 
                        </div>
                        <div className="">
                        <Link className="text-indigo" to={"/ydelser/indre-barn-terapi"}>Indre barn terapi</Link> 
                        </div>
                        <div className="">
                        <Link className="text-indigo" to={"/ydelser/individuel-terapi"}>Individuel terapi</Link>
                        </div>
                    </div>
                    <div className="gap-3 flex-gap flex flex-wrap flex-col">
                        <b className="text-gray-700">
                            Kontakt info
                        </b>
                        <div className="leading-[175%]">
                            <p className="[margin-block-start:0] [margin-block-end:0px]">
                                Hovedgaden 55B, 2. sal
                            </p>
                            <p className="m-0">2970 Hørsholm</p>
                        </div>
                        <div className="">+45 27 402 703</div>
                        <a
                            className=" [text-decoration:underline] text-[inherit]"
                            href="mailto:zenna@zenna.dk"
                            target="_blank"
                        >
                            zenna@zenna.dk
                        </a>
                        <div className="">
                            Find vej hertil
                        </div>
                    </div>

                    <div className="gap-3 flex flex-wrap flex-col">
                        <b className=" text-gray-700">
                        Praktisk
                        </b>
                        <div className="">Min baggrund</div>
                        <div className="">Artikler</div>
                        <div className="">Facebook</div>
                        <div className="">Links</div>
                    </div>



                </div>
                {/* <div className="absolute top-[360.5px] left-[-0.5px] box-border w-[1401px] h-px border-t-[1px] border-solid border-gray-900" /> */}
                <div className="flex flex-wrap items-center justify-center mx-auto gap-4 max-md:pt-10 pt-10">
                    <Link className="menu-item max-w-sm p-1 no-underline text-gray-700" to="/">Hjem</Link>
                    <Link className="menu-item max-w-sm p-1 no-underline text-gray-700" to="/min-bagrund"> Min baggrund</Link>
                    <Link className="menu-item max-w-sm p-1 no-underline text-gray-700" to="/etik">Etik</Link>
                    <Link className="menu-item max-w-sm p-1 no-underline text-gray-700" to="/persondatapolitik">Persondatapolitik</Link>
                    <Link className="menu-item max-w-sm p-1 no-underline text-gray-700" to="/privatlivspolitik">Privatlivspolitik</Link>
                </div>
                <div className="relative flex justify-center pt-10">
                    Copyright © 2022 Zenna Allesøe
                </div>
            </footer>
        </>
    );
};

export default Footer;
