import { useNavigate } from 'react-router-dom';

// Define the props interface
interface NavItemProps {
  to: string;
  title: string;
  children: React.ReactNode;
}

// NavItem component with TypeScript
const NavItem: React.FC<NavItemProps> = ({ to, title, children }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(to);
  };

  return (
    <li onClick={handleClick} style={{ cursor: 'pointer' }} className="list-none max-w-sm p-1 no-underline text-white border border-gray-200 rounded-lg hover:bg-[#7d406c]" title={title}>
      {children}
    </li>
  );
};

export default NavItem;