import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Fragment, Suspense, lazy, useEffect } from "react";
const Forside = lazy(() => import('./pages/Forside'));
const Priser = lazy(() => import('./pages/Priser'));
const Ydelser = lazy(() => import('./pages/Ydelser'));
const Kontakt = lazy(() => import('./pages/Kontakt'));
const MinBagrund = lazy(() => import('./pages/MinBagrund'));
const Services = lazy(() => import('./pages/Ydelsen'));
const Artiklen = lazy(() => import('./pages/Artiklen'));
const Artikler = lazy(() => import('./pages/Artikler'));
const IndividuelTherapy = lazy(() => import('./pages/ydelser/IndividuelTerapi'));
const IndreBarnTerapi = lazy(() => import('./pages/ydelser/IndreBarnTerapi'));
const HSP = lazy(() => import('./pages/ydelser/HSP'));
const Healing = lazy(() => import('./pages/ydelser/Healing'));
const TerapiForUnge = lazy(() => import('./pages/ydelser/TerapiForUnge'));
const KriseTerapi = lazy(() => import('./pages/ydelser/KriseTerapi'));
const StressTerapi = lazy(() => import('./pages/ydelser/StressTerapi'));
const Hjertrum = lazy(() => import('./pages/artikler/Hjertrum'));
const KoginitivTerapi = lazy(() => import('./pages/artikler/KoginitivTerapi'));
const Skyggesider = lazy(() => import('./pages/artikler/Skyggesider'));
const SkyggesiderYdelse = lazy(() => import('./pages/ydelser/Skyggearbejde'));
const Meditation = lazy(() => import('./pages/artikler/Meditation'));
const Mindfullness = lazy(() => import('./pages/artikler/Mindfullness'));
const HjerterumSite = lazy(() => import('./pages/HjerterumSite'));
const Etik = lazy(() => import('./pages/Etik'));
const PrivatlivsPolitik = lazy(() => import('./pages/PrivatlivsPolitik'));
const Persondatapolitik  = lazy(() => import('./pages/Persondatapolitik '));
const NotFound = lazy(() => import('./pages/NotFound'));
import "react-toastify/dist/ReactToastify.css";
import { useConsent } from "react-hook-consent";
import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'AW-1042065901',
    dataLayer: {
        userProject: 'Siden',
        page: 'home'
    }
}
 

import Layout from "./layout";
import Parterapi from "./pages/ydelser/Parterapi";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const { consent } = useConsent();

  
  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);



  function RedirectTo404() {
    let navigate = useNavigate();
    useEffect(() => {
      navigate('/404');
    }, [navigate]);
  
    return null;
  }

  useEffect(() => {
    if (consent && consent.includes('analytics')) {      
      TagManager.initialize(tagManagerArgs)    
    }
  }, [consent]);

  return (
    <>
      <Routes >
      <Route path="/" element={<Layout />}>
      <Route path="/" element={<Suspense fallback={<div>Loading...</div>}><Forside /></Suspense>} />

        <Route path="/priser" element={<Suspense fallback={<div>Loading...</div>}><Priser /></Suspense>} />

        {/* _________________________ YDELSER_____________________________________ */}
        <Route path="/ydelser" element={<Suspense fallback={<div>Loading...</div>}><Ydelser /></Suspense>} />
        <Route
          path="/ydelser/individuel-terapi"
          element={<Suspense fallback={<div>Loading...</div>}><Services
          headerOne="Individuel terapi"
          headerTwo="“Giv mig sindsro til at acceptere det jeg ikke kan ændre, mod til at ændre det jeg kan, og visdom til at skelne derimellem”- Frans af Assisis"
          img="../newBird.png"
          section={<Suspense fallback={<div>Loading...</div>}><IndividuelTherapy /></Suspense>}
        /></Suspense>
            
          }
        />

        <Route
          path="/ydelser/terapi-for-unge"
          element={
            <Suspense fallback={<div>Loading...</div>}><Services
              headerOne="Terapi for unge"
              headerTwo="“Alt hvad vi behøver for at modtage direkte hjælp, er at bede om den….,
        Og alligevel er det at bede om hjælp det, der falder os sværest”
        - Sogyal Rinpoche"
              img="../terapi_unge.jpeg"
              section={<Suspense fallback={<div>Loading...</div>}><TerapiForUnge /></Suspense>}
            /></Suspense>
          }
        />

        <Route
          path="/ydelser/hsp"
          element={
            <Suspense fallback={<div>Loading...</div>}><Services
              headerOne="HSP/Særligt sensitive"
              headerTwo="“Det er virkelig en kærlighedsgerning blot at sidde for sig selv og være stille en tid”- Jon Kabat-Zinn"
              img="../dise-baghave.png"
              section={ <Suspense fallback={<div>Loading...</div>}><HSP /></Suspense>}
            /></Suspense>
          }
        />

        <Route
          path="/ydelser/healing"
          element={
            <Suspense fallback={<div>Loading...</div>}><Services
              headerOne="Healing"
              headerTwo="“Indre ro kan vi først opnå når vi begynder at praktisere tilgivelse. Tilgivelse er at give slip på fortiden, og er derfor vejen til at korrigere vores fejlopfattelser”- Gerald Jampolsky"
              img="../gureso.png"
              section={ <Suspense fallback={<div>Loading...</div>}><Healing /></Suspense>}
            /></Suspense>
          }
        />

        <Route
          path="/ydelser/indre-barn-terapi"
          element={
            <Suspense fallback={<div>Loading...</div>}><Services
              headerOne="Indre barn terapi"
              headerTwo={
                <Fragment>
                  “Hvad er du, at du ikke skulle være noget værd – et menneske
                  som de andre
                  <br />
                  Hvad er du, et menneske som Gud har skabt med et værd – et
                  mesterværk som de andre
                  <br />
                  Hvad er du, at du ikke skulle værne det værd og – elske dig
                  selv og de andre” <br /> - Esther Gress
                </Fragment>
              }
              customWidth="w-[13%] text-left"
              img="../indre_barn.jpeg"
              section={ <Suspense fallback={<div>Loading...</div>}><IndreBarnTerapi /></Suspense>}
            /></Suspense>
          }
        />

        <Route
          path="/ydelser/stressterapi"
          element={
            <Suspense fallback={<div>Loading...</div>}><Services
              headerOne="Stressterapi"
              headerTwo="”Velkommen være du,			
          som hviler i dit nu			
          som kan den kunst at være
          og næres af det nære” - Piet Hein fra Et liv i livets læ – Husk at elske
          "
              img="../stress.jpeg"
              section={ <Suspense fallback={<div>Loading...</div>}><StressTerapi /></Suspense>}
            /></Suspense>
          }
        />

        <Route
          path="/ydelser/kriseterapi"
          element={
            <Suspense fallback={<div>Loading...</div>}><Services
              headerOne="Kriseterapi"
              headerTwo="”Trods enhver hindring	som den strømmende flod møder:
          alt vand som adskilles af
          banker og rev,
          løber dog atter sammen” – Manyoshu fra Japans visdom - livsvisdom
          "
              img="../kriseterapi.jpeg"
              section={<Suspense fallback={<div>Loading...</div>}><KriseTerapi /></Suspense>}
            /></Suspense>
          }
        />
        <Route
          path="/ydelser/skyggearbejde"
          element={
            <Suspense fallback={<div>Loading...</div>}><Services
              headerOne="Skyggearbejde - Bliv bevidst om dine skyggesider"
              headerTwo="”Ud af mørkets bløde favn
              kommer drengen uden navn
              du kan se ham li’så let
              og kan låne hans kasket!
              dér hvor mørket danner bro
              kan I lege, begge to!” – Eva Matjeka fra: ”Hvad mon mørket bringer”
          "
              img="../skyggearbejde.png"
              section={ <Suspense fallback={<div>Loading...</div>}><SkyggesiderYdelse /></Suspense>}
            /></Suspense>
          }
        />
         <Route
          path="/ydelser/parterapi"
          element={
            <Suspense fallback={<div>Loading...</div>}><Services
              headerOne="Parterapi "
              headerTwo={
                <Fragment>
                  “If you always do
                  <br />
                  What you always done
                  <br />
                  You will always get
                  <br />
                  What you always got” <br /> - Henry Ford
                </Fragment>
              }
              img="../Parterapi2.jpeg"
              section={ <Suspense fallback={<div>Loading...</div>}><Parterapi /></Suspense>}
            /></Suspense>
          }
        />
        {/* _________________________ YDELSER_____________________________________ */}

        {/* _________________________ Artikler_____________________________________ */}
        <Route path="/artikler" element={<Suspense fallback={<div>Loading...</div>}><Artikler /></Suspense>} />
        <Route
          path="/artikler/hjerterum"
          element={
            <Suspense fallback={<div>Loading...</div>}><Artiklen
              headerOne="Hjerterum- øvelser og meditationer"
              img="../chap1.webp"
              imgMobile="../chap1-50.webp"
              section={<Suspense fallback={<div>Loading...</div>}><Hjertrum /></Suspense>}
            /></Suspense>
          }
        />
        <Route
          path="/artikler/kognitiv-terapi"
          element={
            <Suspense fallback={<div>Loading...</div>}><Artiklen
              headerOne="Kognitiv terapi"
              img="../kognitiv.webp"
              imgMobile="../kognitiv-50.webp"
              section={ <Suspense fallback={<div>Loading...</div>}><KoginitivTerapi /></Suspense>}
            /></Suspense>
          }
        />
        <Route
          path="/artikler/skyggesider"
          element={
            <Suspense fallback={<div>Loading...</div>}><Artiklen
              headerOne="Skyggesider- Hvad mon mørket gemmer?"
              img="../skyggesider_hvad_mon_moerket_gemmer.jpg"
              imgMobile="../skyggesider_hvad_mon_moerket_gemmer.jpg"
              section={ <Suspense fallback={<div>Loading...</div>}><Skyggesider /></Suspense>}
            /></Suspense>
          }
        />
        <Route
          path="/artikler/meditation"
          element={
            <Suspense fallback={<div>Loading...</div>}><Artiklen
              headerOne="Meditation -en livgivende vane"
              img="../stenbunke.webp"
              imgMobile="../stenbunke-50.webp"
              section={<Suspense fallback={<div>Loading...</div>}><Meditation /></Suspense>}
            /></Suspense>
          }
        />
        <Route
          path="/artikler/mindfullness"
          element={
            <Suspense fallback={<div>Loading...</div>}><Artiklen
              headerOne="Mindfulness"
              img="../strand2.webp"
              imgMobile="../strand2-50.webp"
              section={  <Suspense fallback={<div>Loading...</div>}><Mindfullness /></Suspense>}
            /></Suspense>
          }
        />
        {/* _________________________ Artikler_____________________________________ */}

        <Route path="/kontakt" element={<Suspense fallback={<div>Loading...</div>}><Kontakt /></Suspense>} />

        <Route path="/min-bagrund" element={<Suspense fallback={<div>Loading...</div>}><MinBagrund /></Suspense>} />

        <Route path="/hjerterum" element={<Suspense fallback={<div>Loading...</div>}><HjerterumSite /></Suspense>} />

        <Route path="/privatlivspolitik" element={<Suspense fallback={<div>Loading...</div>}><PrivatlivsPolitik /></Suspense>} />
        <Route path="/persondatapolitik" element={<Suspense fallback={<div>Loading...</div>}><Persondatapolitik /></Suspense>} />
        <Route path="/etik" element={<Suspense fallback={<div>Loading...</div>}><Etik /></Suspense>} />
        <Route path="/404" element={<Suspense fallback={<div>Loading...</div>}><NotFound /></Suspense>} />
        <Route path="*" element={<Suspense fallback={<div>Loading...</div>}><RedirectTo404 /> </Suspense>} />
        </Route>
      </Routes>
    </>
  );
}
export default App;
