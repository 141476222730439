import { FunctionComponent } from "react";
import { Helmet } from "react-helmet";

const Parterapi: FunctionComponent = ({}) => {
  return (
    <div className="flex flex-col mx-auto">
      <Helmet>
        <meta
          name="description"
          content=""
        />
        <meta charSet="utf-8" />
        <meta
          name="keywords"
          content=""
        />
        <title>Zenna Allesøe ydelser - Parterapi i Hørsholm</title>
        <link rel="canonical" href="https://www.zenna.dk/ydelser/parterapi" />
      </Helmet>
      <h2 className="pt-8">
      Få hjælp til at skabe et stabilt og velfungerende parforhold.
      </h2>
      <p >
        De fleste par oplever før eller siden problemer i deres parforhold. Ofte
        kan man løse det selv, men nogle gange kan det være en god ide, at få
        professionel hjælp til at løse problemerne og få hjælp til at skabe et
        rum, hvor parsamtalen er konstruktiv og fremadrettet – gerne før
        konflikterne bliver for fastlåste.
      </p>
      <h3>
        Her kan parterapi være en stor hjælp:
      </h3>
      <ul className="gap-2 flex flex-col list-outside">
        <li>For mange par kan parterapi være en god forebyggende hjælp til at (gen)skabe et stabilt og kærligt forhold, før problemerne vokser sig store og måske uoverskuelige. </li>
        <li>For andre handler det om at få hjælp til at finde ind til hinanden igen, når forholdet er kommet ud på et sidespor, hvor den ene eller begge er utilfredse, og hvor der måske allerede tænkes i skilsmisse.</li>
        <li>Men også hvis i er endt ud i et ønske om at skilles, er det relevant at få hjælp via en parterapi til at få en lærerig, rolig og ordentlig skilsmisse – særligt hvis der er børn involveret.</li>
        <li>Parterapi kan også være en rigtig godt hjælp i forbindelse med de mange forskelligartede udfordringer, der ofte opstår i forbindelse med sammenbragte børn, når den ene eller begge har børn i forvejen.</li>
      </ul>
      <h3>
      Hvad kan i forvente at møde i terapien:
      </h3>
      <p>
      Gennem samtale og øvelser hjælper jeg jer til at få en dybere forståelse for jer selv og hinanden. I hjælpes til at kunne kommunikere roligt og løsningsorienteret, så i igen kan finde ind til den kærlighed, der oprindeligt førte jer sammen.
      </p>
      <p>
      Mit udgangspunkt er at invitere jer til en positiv og indsigtsgivende tilgang til problemerne, hvor vi sammen søger at afdække jeres ressourcer, så i kan bruge dem aktivt til at løse det svære.
      <span>Jeg vil hjælpe jer til at lære og opbygge en åben, klar og konstruktiv dialog, som i kan tage med jer videre i livet. En indsigtsgivende og tillidsskabende dialogform, hvor forståelsen for jer selv og hinanden vil blive en væsentlig grundsten i jeres parforhold.</span>
      </p>
      <p>
        I min hjælp til jer tager jeg udgangspunkt i metoden Imago-terapi, men når det er relevant inddrager jeg også redskaber fra andre metoder.
      </p>
      
    </div>
  );
};

export default Parterapi;
