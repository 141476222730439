import { Outlet } from 'react-router-dom';
import NavBar from './components/Navbar';
import Footer from './components/Footer';

const Layout = () => (

    <div className="felx flex-col text-2xs text-indigo font-atkinson-hyperlegible">
      <div className="absolute bg-indigo w-full h-[500px] max-xl:h-[400px] max-md:h-[230px]" />
      <img
        className="absolute w-[100%] overflow-hidden top-[400px] max-xl:top-[390px] max-md:top-[220px]"
        alt="Vector"
        src="../newvector.png"
      />


    {/* Outlet will render the current route's component */}
    <main className="bg-beige w-full min-h-screen overflow-hidden text-left text-2xs text-gray-700 font-atkinson-hyperlegible">
        <NavBar></NavBar>
      <Outlet />
    </main>

    {/* Footer */}
    <Footer></Footer>
  </div>
);

export default Layout;
